import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core"
import { styleConfig } from "../themesAndStyles/styleConfig"

const useStyles = makeStyles((theme) => ({
  footer: {
    // height: styleConfig.footerHeight,
    padding: 40,
    background: styleConfig.navColor,
    color: "white",
    fontWeight: "bolder",
    fontSize: 12,
    borderTop: "solid 1px black",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "@media screen and (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    "&:hover": {
      color: styleConfig.navActiveColor,
    },
  },
  item: (props: { height: number }) => ({
    display: "flex",
    alignItems: "center",
    height: props.height,
    padding: 10,
    borderLeft: "1px solid white",
  }),
  highestItem: {
    padding: 10,
    borderLeft: "1px solid white",
  },
}))

const Footer: React.FC = () => {
  const [height, setHeight] = useState(0)
  const classes = useStyles({ height })

  useEffect(() => {
    const highest = document.getElementById("highest") as HTMLDivElement
    setHeight(highest.offsetHeight)
  }, [])

  return (
    <div id="footer" className={classes.footer}>
      <div className={classes.item}>
        <a className={classes.link} href="https://www.lenverstijnen.nl">
          Ⓒ 2020 Len Verstijnen
        </a>
      </div>
      <div className={classes.item}>
        Gruttersdijk 12 <br />
        3514 BG Utrecht
      </div>
      <div id="highest" className={classes.highestItem}>
        <a className={classes.link} href="https://www.gitaarlesinvleuten.nl">
          Guitar Facotory Vleuten - Gitaarlessen in Vleuten
        </a>
        <br />
        <a className={classes.link} href="https://www.guitarfactory.nl">
          Guitar Factory - Gitaarlessen in Utrecht
        </a>
        <br />
        <a className={classes.link} href="https://www.pianofactory.nl">
          Piano Factory - Pianolessen in Utrecht
        </a>
        <br />
        <a className={classes.link} href="https://www.degitaarschool.com">
          De Gitaarschool - Gitaarlessen in Bemmel en Zetten
        </a>
      </div>
    </div>
  )
}

export default Footer
