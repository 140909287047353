// import { theme } from "./theme"
import { colors } from "./colors"

interface StyleConfig {
  navHeight: number
  navColor: string
  navTextColor: string
  navActiveColor: string
  navHoverColor: string
  contentBackground: string
  navFontSize: number
  bannerHeight: number
  mobileSliderHeight: number
  mainFontSize: number | string
  inputVariant: any
  inputColor: any
  inputMargin: any
}

export const styleConfig: StyleConfig = {
  navHeight: 64,
  navColor: colors.primary,
  navTextColor: colors.navText,
  navActiveColor: colors.navActive,
  navHoverColor: colors.navHover,
  // contentBackground: "rgba(187, 222, 251, 0.2)",
  contentBackground: colors.contentBackground,
  navFontSize: 18,
  bannerHeight: 800,
  mobileSliderHeight: 300,
  mainFontSize: "medium",
  inputVariant: "standard",
  inputColor: "primary",
  inputMargin: "dense",
}
