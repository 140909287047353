import { Fade, makeStyles } from "@material-ui/core"
import React, { Fragment, useState } from "react"
import { ExpandLess } from "@material-ui/icons"
import smoothscroll from "smoothscroll-polyfill"
import { colors } from "../themesAndStyles/colors"

// kick off the polyfill!
smoothscroll.polyfill()

export interface BackToTopBtnProps {}

const BackToTopBtn: React.FC<BackToTopBtnProps> = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const classes = makeStyles((theme) => ({
    root: {
      zIndex: 10,
      position: "fixed",
      bottom: 20,
      right: 20,
      color: colors.navActive,
      background: "rgba(119,136,153, 0.5)",
      width: 40,
      height: 40,
      borderRadius: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }))()

  const goToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  window.addEventListener("scroll", (e) => setScrollPosition(window.scrollY))

  return (
    <Fragment>
      <Fade in={scrollPosition >= 80}>
        <div onClick={goToTop} className={classes.root}>
          <ExpandLess />
        </div>
      </Fade>
    </Fragment>
  )
}

export default BackToTopBtn
